import { gql } from 'graphql-request'
import { firmhouseClient } from './client'

export type ProductToSubscriptionType = {
  productId: string,
  quantity: number,
  subscriptionId: number,
  interval?: number,
  intervalUnitOfMeasureType?: string,
  shipmentDate?: string
}

type SubscriptionPlanUpdate = {
  subscribedPlanId: string,
  interval: number,
  intervalUnitOfMeasureType: string,
  shipmentDate: string
}

type SubscriptionExtraFields = {
  token: string,
  interval: number,
  intervalUnitOfMeasureType: string,
  shipmentDate: string,
  note: string,
  timeSlot: string,
}

export const CreateSubscription = () => {
  firmhouseClient.setHeader('X-Subscription-Token', '')
  return firmhouseClient.request(gql`
    mutation {
      createCart(input: {}) {
        cart {
          id
          token
          products {
            id
            shopifyVariantId
          }
        }
        subscription {
          checkoutUrl
          id
          token
          subscribedPlan {
            id
          }
        }
      }
    }
  `).then(data =>  ({
      id: data?.createCart?.subscription?.id,
      url: data?.createCart?.subscription?.checkoutUrl,
      token: data?.createCart?.subscription?.token,
      subscribedPlanId: data?.createCart?.subscription?.subscribedPlan?.id
    })
  )
}

export const getCurrentHFCart = (token: string) => {
  if (!token) return
  firmhouseClient.setHeader('X-Subscription-Token', '')

  return firmhouseClient.request(gql`{
    subscriptions(ids: [${token}]) {
      nodes {
        id,
        signupCompletedAt
      }
    }
  }`)
}

export const UpdateSubscriptionDetails = (subscriptionExtraFields: SubscriptionExtraFields) => {
  firmhouseClient.setHeader('X-Subscription-Token', subscriptionExtraFields.token)
  const shipmentDateId = process.env.FIRMHOUSE_FIRST_SHIPMENT_DATE_EXTRAFIELD_ID
  const intervalUnitOfMeasureTypeId = process.env.FIRMHOUSE_INTERVAL_UNITS_EXTRAFIELD_ID
  const intervalId = process.env.FIRMHOUSE_SHIPMENT_INTERVAL_EXTRAFIELD_ID
  const noteId = process.env.FIRMHOUSE_BEZORGNOTITIE_EXTRAFIELD_ID
  const timeSlotId = process.env.FIRMHOUSE_TIME_SLOT_EXTRAFIELD_ID

  if (
    shipmentDateId &&
    intervalUnitOfMeasureTypeId &&
    intervalId &&
    noteId &&
    timeSlotId
  ) {
    return firmhouseClient.request(gql`
      mutation {
        updateAddressDetails(input: {
          extraFields: [
            {
              extraFieldId: "${shipmentDateId}",
              value: "${subscriptionExtraFields.shipmentDate}"
              
            },
            {
              extraFieldId: "${intervalUnitOfMeasureTypeId}",
              value: "${subscriptionExtraFields.intervalUnitOfMeasureType}"
            },
            {
              extraFieldId: "${intervalId}",
              value: "${subscriptionExtraFields.interval}"
            },
            {
              extraFieldId: "${noteId}",
              value: "${subscriptionExtraFields.note}"
            },
            {
              extraFieldId: "${timeSlotId}",
              value: "${subscriptionExtraFields.timeSlot}"
            }
          ]
        }) {
          subscription {
            checkoutUrl
            id
            token
            extraFields {
              value
            }
          }
        }
      }
    `)
  } else {
    return Promise.reject('Extra-field ID is missing')
  }
}

export const UpdateSubscribedPlan = (data: SubscriptionPlanUpdate) => {
  firmhouseClient.setHeader('X-Subscription-Token', '')

  return firmhouseClient.request(gql`
    mutation {
      updateSubscribedPlan(input: {
        id: ${data.subscribedPlanId},
        nextBillingDate: "${data.shipmentDate}",
        billingCycleInterval: ${data.interval},
        billingCycleIntervalUnit: ${data.intervalUnitOfMeasureType},
      }) {
        subscribedPlan {
          nextBillingDate
          billingCycleInterval
          billingCycleIntervalUnit
        }
      }
    }
  `)
}

export const getCurrentProject = (token) => {
  firmhouseClient.setHeader('X-Subscription-Token', token)

  return firmhouseClient.request(gql`{
    getCurrentProject {
      extraFields {
        id
        name
      }
    }
  }`).then(res => console.log(res))
}

export const AddProductToSubscription = (products: Array<ProductToSubscriptionType>, isSubscribedPlanPresent: boolean) => {
  firmhouseClient.setHeader('X-Subscription-Token', '')
  
  const addProductRequests = products.map(product => {
    const noSubscribedPlanFields = `
      interval: ${product.interval},
      intervalUnitOfMeasureType: ${product.intervalUnitOfMeasureType},
      shipmentDate: "${product.shipmentDate}",
    `

    return firmhouseClient.request(gql`
      mutation {
        createOrderedProduct(input: {
          productId: ${product.productId},
          quantity: ${product.quantity},
          subscriptionId: ${product.subscriptionId},
          ${!isSubscribedPlanPresent ? noSubscribedPlanFields : ''}
        }) {
          orderedProduct {
            id
            shipmentDate
            interval
            intervalUnitOfMeasure
            quantity
          }
          subscription {
            id
            checkoutUrl
          }
        }
      }
    `)
  })

  return Promise.all(addProductRequests)
}