import {
  FC,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from 'react';
import { startsWith, sumBy } from 'lodash';
import { useRouter } from 'next/router';
import { useQuery, useQueryClient } from 'react-query';
import { useToggle } from 'react-use';

import { showToast, track, getCurrentHFCart } from '~lib';
import { getShopifySdk } from '~lib/shopify/client';
import {
  CheckoutAttributesUpdateV2Input,
  CheckoutFragmentFragment,
  CheckoutLineItemInput,
  PaymentProvidersQuery,
  AttributeInput
} from '~lib/shopify/sdk';

const LOCAL_STORAGE_CHECKOUT_ID_KEY = 'checkoutId';
const SUBSCRIPTION_CHECKOUT = 'subscription-checkout';

export const alertMessage = 'Something went wrong';

export interface ShopifyContextType {
  // Checkout
  checkout: CheckoutFragmentFragment;
  updatingCart: boolean;
  addItemToCart: (
    lineItems: CheckoutLineItemInput[],
    lineItemType: string,
  ) => Promise<void>;
  adjustLineItemQuantity: (lineItem: CheckoutLineItemInput) => void;
  removeLineItem: (id: string) => void;
  clearCart: () => void;
  showCartOverlay: boolean;
  toggleCartOverlay: (nextValue?: boolean) => void;
  checkoutIsDisabled: boolean;
  checkoutIsLoading: boolean;
  paymentProviders: PaymentProvidersQuery['shop']['paymentSettings'];
  portion: string;
  setPortion: (portion: string) => void;
  pendingAddToCart: string;
  setPendingAddToCart: (id: string | null) => void;
  toast: (content: string) => void;
  toastContent: string | boolean;
  quantity: number;
  updateCartAttributes: (input: CheckoutAttributesUpdateV2Input) => void;
  addLocalItems: () => void;
  checkLocalItemsContains: (id: string) => Record<string, any> | undefined;
}

export const ShopifyContext = createContext<ShopifyContextType>(undefined);

export const ShopifyProvider: FC = ({ children }) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  // const intl = useIntl();

  const [checkoutId, setCheckoutIdState] = useState('');
  const [checkoutIdInitialized, setCheckoutIdInitialized] = useState(false);
  const [updatingCart, setUpdatingCart] = useState(false);
  const [paymentProviders, setProviders] = useState(null);
  const [itemsToAdd, setItemsToAdd] = useState('');
  const [showCartOverlay, toggleCartOverlay] = useToggle(false);

  const [portion, setPortion] = useState('selectable');
  const [pendingAddToCart, setPendingAddToCart] = useState(null);

  // TODO replace with react-toastify
  const [toastContent, setToastContent] = useState(false);

  const toast = (content) => {
    setToastContent(content);
    setTimeout(() => setToastContent(false), 2000);
  };

  const shopifySdk = useMemo(
    () => getShopifySdk(router?.locale),
    [router?.locale],
  );

  const checkoutIsDisabled = !checkoutId;
  const { data: checkout, isLoading: checkoutIsLoading } = useQuery(
    ['checkout', checkoutId],
    async () =>
      shopifySdk
        .checkout({ id: checkoutId })
        .then(({ node }) => {
          return node as CheckoutFragmentFragment
        }),
    {
      enabled: !checkoutIsDisabled,
    },
  );

  const setSubscriptionCheckoutId = (id: string) => {
    localStorage.setItem(SUBSCRIPTION_CHECKOUT, id);
  };

  const setCheckoutId = (id: string) => {
    localStorage.setItem(LOCAL_STORAGE_CHECKOUT_ID_KEY, id);
    setCheckoutIdState(id);
  };

  /**
   * If there is a checkoutId in localStorage, set it
   * If checkout is completed or invalid, unset the checkoutId
   */

  useEffect(() => {
    const checkoutId = localStorage.getItem(LOCAL_STORAGE_CHECKOUT_ID_KEY);
    if (checkoutId) {
      setCheckoutIdState(checkoutId);
      shopifySdk
        .paymentProviders()
        .then((res) => setProviders(res.shop.paymentSettings))
        .catch((err) => console.log(err));

      setCheckoutIdInitialized(true);
    } else {
      shopifySdk.checkoutCreate({
        input: { lineItems: [] },
      }).then(res => {
        const { checkoutCreate } = res
        setCheckoutId(checkoutCreate.checkout.id)

        shopifySdk
          .paymentProviders()
          .then((res) => setProviders(res.shop.paymentSettings))
          .catch((err) => console.log(err))

        setCheckoutIdInitialized(true)
      })
    }
  }, []);

  /**
   * If checkout is completed or invalid, unset the checkoutId
   */
  useEffect(() => {
    if (
      checkout === null ||
      checkout?.lineItems?.edges?.some(({ node }) => !node.variant) ||
      checkout?.completedAt
    ) {
      setCheckoutId('');
      setSubscriptionCheckoutId('');
    }

    // if there's a checkout, set portion according to items in cart
    if (!portion && checkout?.lineItems?.edges?.length) {
      setPortion(
        checkout?.lineItems?.edges?.[0].node?.variant?.product?.tags
          .find((tag) => startsWith(tag, 'portion'))
          ?.split('_')?.[1],
      );
    }

    const subscriptionCheckoutId = localStorage.getItem('fh-subscription-id')
    
    if (subscriptionCheckoutId && checkoutIdInitialized) {
      getCurrentHFCart(subscriptionCheckoutId).
        then(res => {
          if (res && res?.subscriptions && res?.subscriptions?.nodes) {
            const subscriptionStatus = res.subscriptions.nodes.find(el => el.id == subscriptionCheckoutId)

            if (subscriptionStatus && subscriptionStatus?.signupCompletedAt) {
              localStorage.removeItem('fh-subscription-id')
              clearCart()
            }
          }
        })
    }
  }, [checkout]);

  const quantity = useMemo(() => {
    let toReturn = sumBy(
      checkout?.lineItems?.edges,
      (item) => item?.node?.quantity,
    );
    if (toReturn === 0 && !!itemsToAdd) {
      try {
        toReturn = sumBy(
          JSON.parse(itemsToAdd),
          (item: CheckoutLineItemInput) => item?.quantity,
        );
      } catch (error) {}
    }
    return toReturn;
  }, [checkout, itemsToAdd]);

  // *************************
  // Checkout ****************
  // *************************

  const trackAddToCart = (lineItems: CheckoutLineItemInput[], checkoutData) => {
    lineItems.forEach(({ variantId, quantity }) => {
      const item = checkoutData?.lineItems?.edges?.find(
        ({ node }) => variantId === node.variant.id,
      );

      if (item?.node?.variant) {
        const dimension1 = item.node.variant.selectedOptions?.find(
          ({ name }) => name === 'Size',
        )?.value;

        // track({
        //   event: 'addToCart',
        //   ecommerce: {
        //     value: `${item?.node?.variant?.priceV2?.amount}`,
        //     currencyCode: item?.node?.variant?.priceV2?.currencyCode,
        //     add: {
        //       products: [
        //         {
        //           id: item.node.id,
        //           variant: item.node.variant.sku,
        //           quantity: `${quantity}`,
        //           name: item?.node?.title,
        //           price: `${item?.node?.variant?.priceV2?.amount}`,
        //           brand: 'Plant B',
        //           ...(dimension1 ? { dimension1 } : {}),
        //           category: item.node.variant.product?.tags
        //             ?.find((tag) => tag?.startsWith('collection_'))
        //             ?.replace('collection_', ''),
        //         },
        //       ],
        //     },
        //   },
        // });
      }
    });
  };

  const addItemToCart = async (
    lineItems: CheckoutLineItemInput[],
    lineItemType,
  ) => {
    try {
      setUpdatingCart(true);

      if (!checkoutId) {
        const { checkoutCreate } = await shopifySdk.checkoutCreate({
          input: { lineItems: lineItemType === 'is_box' ? lineItems : [] },
        });

        setCheckoutId(checkoutCreate.checkout.id);

        queryClient.setQueryData(
          ['checkout', checkoutId],
          checkoutCreate.checkout,
        );

        if (lineItemType !== 'is_box') {
          setItemsToAdd(JSON.stringify(lineItems));
        }

        trackAddToCart(lineItems, checkoutCreate.checkout);
      } else {
        if (
          checkout?.lineItems?.edges?.some(
            ({ node }) => !node?.variant?.product?.tags?.includes(lineItemType),
          )
        ) {
          await clearCart();
        }

        const updatedLineItems: CheckoutLineItemInput[] =
          checkout?.lineItems?.edges
            ?.filter(({ node }) =>
              node?.variant?.product?.tags?.includes(lineItemType),
            )
            ?.reduce((acc, { node }) => {
              const changedItem = lineItems.find(
                (input) => input.variantId === node.variant.id,
              );
              if (!!changedItem) {
                return [
                  ...acc.filter((e) => e.variantId !== changedItem.variantId),
                  {
                    variantId: changedItem.variantId,
                    quantity: node.quantity + changedItem.quantity,
                  },
                ];
              }
              return [
                ...acc,
                { variantId: node.variant.id, quantity: node.quantity },
              ];
            }, lineItems);

        if (lineItemType === 'is_box') {
          addLocalItems(updatedLineItems);
        } else {
          if (!!itemsToAdd) {
            const newItems = JSON.parse(itemsToAdd)
            updatedLineItems.forEach(uli => {
              const indexOfLineElement = newItems.findIndex(ni => ni.variantId == uli.variantId)
              const isNewItem = indexOfLineElement === -1

              if (isNewItem) {
                newItems.push(uli)
              } else {
                newItems[indexOfLineElement] = {...uli}
              }
            })

            setItemsToAdd(JSON.stringify(newItems));
            const { checkoutLineItemsReplace } =
              await shopifySdk.checkoutLineItemsReplace({
                checkoutId,
                lineItems: newItems,
              });
        
              queryClient.setQueryData(
                ['checkout', checkoutId],
                checkoutLineItemsReplace.checkout,
              );
          } else {
            if (!!updatedLineItems) {
              const newItems = updatedLineItems

              setItemsToAdd(JSON.stringify(newItems));
                const { checkoutLineItemsReplace } =
                  await shopifySdk.checkoutLineItemsReplace({
                    checkoutId,
                    lineItems: newItems,
                  });
                
                queryClient.setQueryData(
                  ['checkout', checkoutId],
                  checkoutLineItemsReplace.checkout,
                );
            } else {
              setItemsToAdd(JSON.stringify(updatedLineItems));
            }
          }
        }
      }
    } catch (e) {
      console.warn(e);
      showToast(e.message);
      setCheckoutId('');
      setSubscriptionCheckoutId('');
    } finally {
      setUpdatingCart(false);
    }
  };

  const checkLocalItemsContains = (id: string) => {
    if (!!itemsToAdd) {
      const localItems = JSON.parse(itemsToAdd);
      const item = localItems?.find((item) => item?.variantId === id);
      return !!item
        ? { variant: { id: item.variantId }, quantity: item.quantity }
        : undefined;
    }
    return undefined;
  };

  const addLocalItems = async (items?: CheckoutLineItemInput[]) => {
    let updatedLineItems;
    if (items?.length) {
      updatedLineItems = items;
    } else {
      updatedLineItems = JSON.parse(itemsToAdd);
    }
    const { checkoutLineItemsReplace } =
      await shopifySdk.checkoutLineItemsReplace({
        checkoutId,
        lineItems: updatedLineItems,
      });

    queryClient.setQueryData(
      ['checkout', checkoutId],
      checkoutLineItemsReplace.checkout,
    );
    trackAddToCart(updatedLineItems, checkoutLineItemsReplace.checkout);
  };

  const adjustLineItemQuantity = async (lineItem: CheckoutLineItemInput) => {
    try {
      setUpdatingCart(true);

      if (!lineItem.quantity) {
        removeLineItem(lineItem.variantId);
        return;
      }
      if (
        !checkout.lineItems.edges?.length &&
        !!itemsToAdd &&
        JSON.parse(itemsToAdd)?.length
      ) {
        let parsedItems = JSON.parse(itemsToAdd);
        parsedItems = parsedItems.map((item) => {
          if (item.variantId === lineItem.variantId) {
            item.quantity = lineItem.quantity;
          }
          return item;
        });
        setItemsToAdd(JSON.stringify(parsedItems));
      } else {
        const updatedLineItems = checkout.lineItems.edges.map(({ node }) => {
          let item = {
            variantId: node.variant?.id,
            quantity: node.quantity,
            customAttributes: node.customAttributes.map(el => el as AttributeInput)
          }
          if (node.variant?.id === lineItem.variantId) {
            item.quantity = lineItem.quantity
          }

          return item
        })

        const isNewItem = checkout.lineItems.edges.findIndex(({ node }) => node.variant?.id === lineItem.variantId) === -1

        if (isNewItem) {
          updatedLineItems.push({
            variantId: lineItem.variantId,
            quantity: lineItem.quantity,
            customAttributes: lineItem?.customAttributes ? lineItem?.customAttributes : []
          })
        }

        const { checkoutLineItemsReplace } =
          await shopifySdk.checkoutLineItemsReplace({
            checkoutId,
            lineItems: updatedLineItems,
          });

        queryClient.setQueryData(
          ['checkout', checkoutId],
          checkoutLineItemsReplace.checkout,
        );

        setItemsToAdd(JSON.stringify(updatedLineItems));
        trackAddToCart([lineItem], checkoutLineItemsReplace.checkout);
      }
    } catch (e) {
    } finally {
      setUpdatingCart(false);
    }
  };

  const removeLineItem = async (id: string) => {
    const lineItems: CheckoutLineItemInput[] = checkout?.lineItems?.edges
      ?.filter(({ node }) => node.variant.id !== id)
      .map(({ node }) => ({
        variantId: node.variant.id,
        quantity: node.quantity,
        customAttributes: node.customAttributes.map(el => el as AttributeInput)
      }));

    const { checkoutLineItemsReplace } =
      await shopifySdk.checkoutLineItemsReplace({
        checkoutId,
        lineItems,
      });

    if (!!itemsToAdd) {
      let parsedItems = JSON.parse(itemsToAdd);
      parsedItems = parsedItems.filter((item) => item.variantId !== id);
      setItemsToAdd(JSON.stringify(parsedItems));
    }

    queryClient.setQueryData(
      ['checkout', checkoutId],
      checkoutLineItemsReplace.checkout,
    );
  };

  const clearCart = async () => {
    const { checkoutLineItemsReplace } =
      await shopifySdk.checkoutLineItemsReplace({
        checkoutId,
        lineItems: [],
      });
    setItemsToAdd('');
    queryClient.setQueryData(
      ['checkout', checkoutId],
      checkoutLineItemsReplace.checkout,
    );
  };

  const updateCartAttributes = async (input) => {
    const { checkoutAttributesUpdateV2 } =
      await shopifySdk.checkoutAttributesUpdate({
        id: checkoutId,
        input,
      });

    queryClient.setQueryData(
      ['subscriptionCheckout', checkoutId],
      checkoutAttributesUpdateV2.checkout,
    );
  };

  return (
    <ShopifyContext.Provider
      value={{
        // Checkout
        toast,
        toastContent,
        addItemToCart,
        removeLineItem,
        clearCart,
        adjustLineItemQuantity,
        updatingCart,
        checkout,
        showCartOverlay,
        toggleCartOverlay,
        checkoutIsLoading,
        checkoutIsDisabled: checkoutIdInitialized && checkoutIsDisabled,
        paymentProviders,
        portion,
        setPortion,
        pendingAddToCart,
        setPendingAddToCart,
        quantity,
        updateCartAttributes,
        addLocalItems,
        checkLocalItemsContains,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopify = () => {
  const ctx = useContext(ShopifyContext);

  if (!ctx) {
    throw new Error(
      'Shopify context must be used within a shopify context provider',
    );
  }

  return ctx;
};
