import { GraphQLClient } from 'graphql-request';

const getFirmhouseClient = () =>
  new GraphQLClient(
    `${process.env.FIRMHOUSE_ORIGIN}/graphql`,
    {
      headers: {
        'X-Project-Access-Token':
          process.env.FIRMHOUSE_ORIGIN_ACCESS_TOKEN,
      },
    },
  );

export const firmhouseClient = getFirmhouseClient();
