import { Button, Text, Flex, Icon, Close } from '@storyofams/react-ui';

import { Info, Tick, XCircle } from '../Icons';

type ToastProps = {
  msg: string;
  persistant?: boolean;
  type: 'info' | 'success' | 'error' | 'alert';
  onClose?: () => void;
};

const content = {
  info: {
    icon: Info,
    color: 'primary',
  },
  success: {
    icon: Tick,
    color: 'success600',
  },
  error: {
    icon: XCircle,
    color: 'error600',
  },
  alert: {
    icon: Info,
    color: 'error600',
  },
};

export const Toast = ({ msg, type, persistant }: ToastProps) => (
  <Flex
    p={4}
    width="100%"
    boxShadow="toast"
    borderRadius="md"
    position="relative"
    backgroundColor="grey200"
    alignItems="center"
  >
    <Icon fontSize={3} icon={content[type].icon} color={content[type].color} />
    <Text ml={2} fontSize={2} color="grey700" maxWidth="100%" lineHeight={1.5}>
      {msg}
    </Text>
    {persistant && (
      <Button position="absolute" top="16px" right="16px">
        <Icon icon={Close} color="grey700" fontSize="8px" />
      </Button>
    )}
  </Flex>
);
