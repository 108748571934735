import React, { useRef, useEffect } from 'react'
import { withPasswordProtect } from '@storyofams/next-password-protect'
import { DefaultSeo } from 'next-seo'
import { AppProps } from 'next/app'
import Userback from '@userback/widget'

import { useRouter } from 'next/router'
import { Providers } from '~components'
import { seo } from '~config'
import { useDetectKeyboard } from '~hooks'
import CSSreset from '~styles/CSSreset'
import Script from 'next/script'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  import('~lib/sentry').then(m => m.initSentry())
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { locale } = useRouter()

  useDetectKeyboard()

  return (
    <Providers pageProps={pageProps} locale={locale}>
      <CSSreset />
      <DefaultSeo {...seo} />
      <Script
        strategy="afterInteractive"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XACU9h`}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3420131,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      />
      <Script
        strategy="afterInteractive"
        src={
          'https://cdn.grw.reputon.com/assets/widget.js?shop=waldenfoods.myshopify.com'
        }
      />
      <Script
        strategy="afterInteractive"
        onLoad={() => {
          ;(window as any).dataLayer = (window as any).dataLayer || []
          ;(window as any).dataLayer.push({
            event: 'gtm.js',
            'gtm.start': new Date().getTime(),
            'gtm.uniqueEventId': 0,
          })
        }}
        src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM}`}
      />
      <Script
        strategy="afterInteractive"
        id="Cookiebot"
        data-cbid={'fc90c822-6a23-442d-97f8-7e51df3b28e5'}
        data-blockingmode="auto"
        src={'https://consent.cookiebot.com/uc.js'}
        defer
      />
      <Component {...pageProps} />
    </Providers>
  )
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginComponentProps: {
        buttonBackgroundColor: '#010406',
        buttonColor: '#fff',
        logo: '/images/logo.png',
      },
    })
  : MyApp
