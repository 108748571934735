import React, { ReactNode, useRef } from 'react';
import { StoryProvider } from '@storyofams/storyblok-toolkit';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ThemeProvider } from 'styled-components';
import { ShopifyProvider } from '~context';

import theme from '~styles/theme';

type ProviderProps = {
  children: ReactNode;
  /** defaults to empty object */
  pageProps?: any;
  /** defaults to 'en' as per the defaultLocale */
  locale?: string;
};

export const Providers = ({
  children,
  locale = 'en',
  pageProps = {},
}: ProviderProps) => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <ThemeProvider theme={theme}>
          <IntlProvider
            messages={pageProps.messages}
            defaultLocale="en"
            locale={locale}
          >
            <StoryProvider
              token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
              resolveRelations={[]}
            >
              <ShopifyProvider>{children}</ShopifyProvider>
            </StoryProvider>
          </IntlProvider>
        </ThemeProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};
