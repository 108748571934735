const defaultSeo = {
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.voodmeals.com/',
    site_name: 'Vood',
  },
};

export default defaultSeo;
